import Swiper from 'swiper'

new Swiper ('.o-gym-slider__swiper_container', {
    // Optional parameters
    direction: 'horizontal',
    loop: true,
    // slidesPerView: 5,
    slidesPerView: 'auto',
    spaceBetween: 0,
    centeredSlides: false,

    // スライドを止めたい場合は以下をコメントアウト
    autoplay: {
        delay: 5000,
        disableOnInteraction: false,
    },

    // If we need pagination
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
    preloadImages: false,
    // Enable lazy loading
    lazy: true
    // // Navigation arrows
    // navigation: {
    //     nextEl: '.swiper-button-next',
    //     prevEl: '.swiper-button-prev',
    // },
    //
    // // And if we need scrollbar
    // scrollbar: {
    //     el: '.swiper-scrollbar',
    // },
})

// このスライダーはsp版のみに適用させています
if (window.matchMedia('(max-width: 767px)').matches) {
    new Swiper('.o-top-find_by_area__swiper_container', {
        // Optional parameters
        direction: 'horizontal',
        loop: true,
        slidesPerView: 'auto',
        spaceBetween: 20,
        centeredSlides: true,
        preloadImages: false,
    })
}

new Swiper ('.o-gym-images__swiper_container', {
    direction: 'horizontal',
    loop: true,
    slidesPerView: 'auto',
    centeredSlides: true,

    autoplay: {
        delay: 5000,
        disableOnInteraction: false,
    },

    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },

    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    preloadImages: false,
    lazy: true
})
