import $ from 'jquery'
import ClipboardJS from 'clipboard'

$(() => {
    new ClipboardJS('.clipboard-copy-button');
    $('.clipboard-copy-button').click((e) => {
        e.preventDefault()

        $('.o-share-dialog__copied_message').fadeIn(300, () => {
            setTimeout(()=> {
                $('.o-share-dialog__copied_message').fadeOut(600)
            }, 600)
        })
    })
})
