import { library, dom } from "@fortawesome/fontawesome-svg-core";
import {
    faTimesCircle,
    faEnvelope,
    faCopy,
    faHeart,
} from "@fortawesome/free-regular-svg-icons"

library.add(
    faTimesCircle,
    faEnvelope,
    faCopy,
    faHeart,
)

dom.i2svg()
