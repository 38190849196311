import { library, dom } from "@fortawesome/fontawesome-svg-core";
import {
    faHeart,
    faMapMarkerAlt,
    faSearch,
    faBars,
    faHome,
    faCheck,
    faCaretUp,
    faCaretLeft,
    faCrown,
    faTags,
    faChevronDown,
    faChevronUp,
    faChevronRight,
    faYenSign,
    faCaretRight,
    faTag,
    faBolt,
    faCalculator,
    faMap,
    faShare,
    faTrash,
    faRunning,
    faExternalLinkAlt,
} from "@fortawesome/free-solid-svg-icons"

library.add(
    faHeart,
    faMapMarkerAlt,
    faSearch,
    faBars,
    faHome,
    faCheck,
    faCaretUp,
    faCaretLeft,
    faCrown,
    faTags,
    faChevronDown,
    faChevronUp,
    faChevronRight,
    faYenSign,
    faCaretRight,
    faTag,
    faBolt,
    faCalculator,
    faMap,
    faShare,
    faTrash,
    faRunning,
    faExternalLinkAlt
)

dom.i2svg()
