// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// require("@rails/ujs").start()
// require("turbolinks").start()
// require("@rails/activestorage").start()
// require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// images
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

// stylesheets
import 'reset-css'
import 'swiper/css/swiper.css'
import '../stylesheets/application'

// icons
import './fontawsome/brands.js'
import './fontawsome/regular.js'
import './fontawsome/solid.js'

// javascripts
import './add_favorite.js'
import './copy_to_clipboard.js'
import './dialog.js'
import './lazysizes.js'
import './scroll_back.js'
import './search_panel_accordion.js'
import './search_panel_reset.js'
import './swipe.js'
import './sticky_header.js'


document.addEventListener('lazybeforeunveil', function(e){
    var bg = e.target.getAttribute('data-bg')
    if(bg){
        e.target.style.background = 'url(' + bg + ') 0 40% no-repeat'
        e.target.style.backgroundSize = 'cover'
    }
})
