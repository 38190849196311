import { library, dom } from "@fortawesome/fontawesome-svg-core";
import {
    faFacebook,
    faTwitter,
} from "@fortawesome/free-brands-svg-icons"

library.add(
    faFacebook,
    faTwitter,
)

dom.i2svg()
