import $ from 'jquery'

$(() => {
    $('.o-search-panel__expanding').on('click', event => {
        const $target = $(event.currentTarget)
        $('.o-search-panel__search_bar_form_lower').slideDown('normal')
        $target.css('display', 'none')
        $('.o-search-panel__closing').css('display', 'block')
    })

    $('.o-search-panel__closing').on('click', event => {
        const $target = $(event.currentTarget)
        $('.o-search-panel__search_bar_form_lower').slideUp('normal')
        $target.css('display', 'none')
        $('.o-search-panel__expanding').css('display', 'block')
    })
})
