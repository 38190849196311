document.addEventListener('DOMContentLoaded', () => {
  const threshold = 450
  const stickyHeader = document.getElementById('js-sticky-header')
  const stickyClass = 'is-sticky-header'
  let timer = null

  function setAttr() {
    if (window.pageYOffset > threshold) {
      document.body.classList.add(stickyClass)
    } else {
      document.body.classList.remove(stickyClass)
    }
  }

  function onScroll() {
    if (window.matchMedia('(max-width: 767px)').matches) {
      clearTimeout(timer)
      timer = setTimeout(() => setAttr(), 10)
    }
  }

  if (stickyHeader != null) {
    window.addEventListener('scroll', onScroll)
  }
})
