import $ from 'jquery'

$(() => {
    // show popupボタンクリック時の処理
    $('.js-dialog').click((e) => {
        e.preventDefault()
        $('.o-share-background, .o-share-dialog').show();
        $('.o-share-background').animate({opacity: 0.4}, 200, 'swing')
        $('.o-share-dialog').addClass('o-share-dialog--active').animate({opacity: 1}, 200, 'swing', 100)
    });

    // レイヤー/ポップアップのcloseボタンクリック時の処理
    $('.o-share-dialog__close_button, .o-share-background').click((e) => {
        e.preventDefault()
        $('.o-share-background').animate({opacity: 0}, 100, 'swing')
        $('.o-share-dialog').animate({opacity: 0}, 100, 'swing', () => {
            $('.o-share-dialog').removeClass('o-share-dialog--active')
            $('.o-share-background, .o-share-dialog').hide();
        })
    });
});
