import $ from 'jquery'

$(() => {
    // badges
    const syncFavoritesAmounts = () => {
        $.get('/favorites/amounts', (response) => {
            const total = response.total
            if (total > 0) {
                $('.js-favorites-badge').text(total).css('display', 'inline-block')
            } else {
                $('.js-favorites-badge').text(total).css('display', 'none')
            }

            if (total > 9) {
                $('.js-favorites-badge').css('font-size', '0.8rem').css('padding', '2px')
            } else {
                $('.js-favorites-badge').css('font-size', '1.2rem').css('padding', '3px')
            }
        })
    }

    syncFavoritesAmounts()

    $('.js-add-favorite').click((e) => {
        e.preventDefault()
        $.post('/favorites',
          {
            'gym_id': $(e.currentTarget).attr('data-gym-id')
          },
          () => {
            $('.o-added-favorite-dialog').addClass('o-added-favorite-dialog--active')
            $('.o-added-favorite-background, .o-added-favorite-dialog').show()
            $('.o-added-favorite-background').animate({opacity: 0.4}, 200, 'swing')
            $('.o-added-favorite-dialog').animate({opacity: 1}, 200, 'swing', () => {
                setTimeout(()=> {
                    $('.o-added-favorite-background').animate({opacity: 0}, 400, 'swing')
                    $('.o-added-favorite-dialog').animate({opacity: 0}, 400, 'swing', () => {
                        $('.o-added-favorite-dialog').removeClass('o-added-favorite-dialog--active')
                        $('.o-added-favorite-background, .o-added-favorite-dialog').hide()
                    })
                }, 600)
            })

            syncFavoritesAmounts()
          }
        )
    })


    $('.js-as-favorite-icon').click((e) => {
        e.preventDefault()
        // $(e.currentTarget).removeClass('far').addClass('fas a-icon--favorite')
        $(e.currentTarget).html('<i class="fas fa-heart a-icon--favorite" />')
    })


    $('.js-present-added-favorites-button').click((e) => {
        e.preventDefault();
        $('#o-gym-tab_bar__button_added_as_favorite').removeClass('o-gym-tab_bar__button--hidden')
        $(e.currentTarget).addClass('o-gym-tab_bar__button--hidden')
    })
})
