import $ from 'jquery'

// 参考 http://serinaishii.hatenablog.com/entry/2015/11/06/超簡単%26コピペでOK！ページトップへ戻るボタンの
$(() => {
    let scrollBackButton=$('#scroll-back-button');
    $(window).scroll(function(){
        if($(this).scrollTop() > 400){
            //---- 画面を80pxスクロールしたら、ボタンを表示する
            scrollBackButton.fadeIn('fast');
        }else{
            //---- 画面が80pxより上なら、ボタンを表示しない
            scrollBackButton.fadeOut('fast');
        }
    });

    scrollBackButton.click(function(){
        $('body,html').animate({scrollTop: 0}, 500);
        return false;
    });
})
